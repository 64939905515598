
import Vue from "vue";

import ActivitiesMixin from "@/components/ActivitiesView/ActivitiesMixin.vue";
import CommunicationActivitiesTimelineItem from "./CommunicationActivitiesTimelineItem.vue";
interface ICommunicationActivitiesData {
  error: string;
  message: string;
}

export default Vue.extend({
  name: "DocumentCategoryAllActivitiesView",
  components: { CommunicationActivitiesTimelineItem },
  mixins: [ActivitiesMixin],

  data(): ICommunicationActivitiesData {
    return {
      error: "",
      message: ""
    };
  },
  computed: {
    queryOverride(): any {
      return {
        name__in: `
        DELETE_COMMUNICATION_SUCCESS,
        DELETE_COMMUNICATION_FAILURE
          `.replace(/\s/g, ""),
        activityType: "CommunicationActivity"
      };
    }
  }
});
